import LoginForm from "./loginForm.js";
import Logo from "../../media/logo.png";
import "../register/register.css";

const Login = () => {
  return (
    <div className="register">
      <div className="row-columns">
        <div className="auth">
          <div className="page-meta">
            <h1 className="title">Welcome Back</h1>

            {/* <p className="auth-par">
              Login to your HackPrinceton account to manage your application.
            </p> */}
          </div>

          <LoginForm />

          <img
            className="logo"
            src={Logo}
            alt="HackPrinceton semester-specific theme graphic"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
