import { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";
import useAuth from "../../hooks/useAuth";

const DashboardButton = ({ email, status }) => {
  const { authState } = useAuth();
  const [waitlistSelection, setWaitlistSelection] =
    useState("waitlist_confirmed");
  const [attendanceSelection, setAttendanceSelection] = useState("confirmed");
  const [hackathonStatus, setHackathonStatus] = useState("not_open");

  const handleWaitlistSelectChange = (event) => {
    setWaitlistSelection(event.target.value);
  };

  const handleAttendanceSelectChange = (event) => {
    // console.log(event.target.value);
    setAttendanceSelection(event.target.value);
  };

  const handleWaitlistSubmit = async (event) => {
    event.preventDefault();

    // console.log("Attendance Selection: " + waitlistSelection);

    if (waitlistSelection === "waitlist_confirmed") {
      try {
        // console.log("Confirming spot on waitlist");
        const response = await axiosInstance.post(
          `/api/applications/confirm-waitlist`
        );
        // console.log("Response:", response.data);
        window.location.reload(true);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else if (waitlistSelection === "declined") {
      try {
        // console.log("Declining attendance");
        const response = await axiosInstance.post(`/api/applications/decline`);
        // console.log("Response:", response.data);
        window.location.reload(true);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleAttendanceSubmit = async (event) => {
    event.preventDefault();
    // console.log("Attendance Selection: " + attendanceSelection);

    if (attendanceSelection === "confirmed") {
      try {
        // console.log("Confirming attendance");
        const response = await axiosInstance.post("/api/applications/confirm");
        // console.log("Response:", response.data);
        window.location.reload(true);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else if (attendanceSelection === "declined") {
      // console.log("Here");
      try {
        // console.log("Declining attendance");
        const response = await axiosInstance.post(`/api/applications/decline`);
        // console.log("Response:", response.data);
        window.location.reload(true);
      } catch (error) {
        // console.error("Error submitting form:", error);
      }
    }
  };

  useEffect(() => {
    const fetchHackathonStatus = async () => {
      try {
        const response = await axiosInstance.get(
          "/api/applications/cycle/status"
        );
        // console.log(response);
        setHackathonStatus(response.data);
      } catch (err) {
        // console.log(err);
      }
    };

    if (authState.isAuthenticated) {
      fetchHackathonStatus();
    }
  }, []);

  if (
    (status === "unsubmitted" || status === "started") &&
    hackathonStatus === "open"
  ) {
    return (
      <div className="app-button-pad">
        <a href="/apply" className="apply-button">
          Launch Application →
        </a>
      </div>
    );
  } else if (status === "submitted" && hackathonStatus === "open") {
    return (
      <div className="app-button-pad">
        <a className="apply-button" href="/apply">
          Edit Application →
        </a>
      </div>
    );
  } else if (
    status === "waitlisted" &&
    (hackathonStatus === "released" ||
      hackathonStatus === "confirmation_closed")
  ) {
    return (
      <form className="dashboard-waitlist-form" onSubmit={handleWaitlistSubmit}>
        <label className="dashboard-waitlist-label">
          Would you like to be placed on the waitlist for HackPrinceton?
        </label>
        <select
          className="dashboard-waitlist-select"
          onChange={handleWaitlistSelectChange}
          required
        >
          <option value="waitlist_confirmed">
            Yes, I would like to be on the waitlist.
          </option>
          <option value="declined">
            No, I would not like to be on the waitlist.
          </option>
        </select>
        <button type="submit" className="dashboard-waitlist-button">
          Submit
        </button>
      </form>
    );
  } else if (
    (status === "accepted" && hackathonStatus === "released") ||
    (status === "waitlist_accepted" &&
      (hackathonStatus === "released" ||
        hackathonStatus === "confirmation_closed" ||
        hackathonStatus === "waitlist_closed"))
  ) {
    return (
      <form
        className="dashboard-waitlist-form"
        onSubmit={handleAttendanceSubmit}
      >
        <label className="dashboard-waitlist-label">
          Will you be able to attend HackPrinceton?
        </label>
        <select
          className="dashboard-waitlist-select"
          defaultValue="Yes, I'm looking forward to it!"
          onChange={handleAttendanceSelectChange}
          required
        >
          <option value="confirmed">Yes, I'm looking forward to it!</option>
          <option value="declined">
            No, unfortunately I can no longer attend.
          </option>
        </select>
        <button type="submit" className="dashboard-waitlist-button">
          Submit
        </button>
      </form>
    );
  }

  return <div></div>;
};

export default DashboardButton;
