import axios from 'axios';
import axiosRefresh from './axiosRefresh';

const axiosInstance = axios.create({
  // baseURL: "https://hackprinceton-registration-25f70f11442b.herokuapp.com",
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await axiosRefresh.post('/api/auth/token/refresh');
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
