import { useEffect, useState } from "react";
import "./users.css";
import "./dataTable.css";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../api/axios";

const Users = () => {
  const { authState } = useAuth();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState("first");

  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (authState.user && authState.user.role !== "admin") {
      navigate("/login"); // Redirect non-admin users to login
    } else {
      fetchApplications(page, perPage, filters);
    }
  }, [page]);

  const fetchApplications = async (page, perPage, filters) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/api/applications/all", {
        params: {
          page,
          per_page: perPage,
          filters: JSON.stringify(filters),
        },
      });
      const { applications, total, pages } = response.data;
      // console.log(applications);
      setApplications(applications);
      setTotal(total);
      setPages(pages);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching applications:", error);
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= pages) {
      setPage(newPage);
    }
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    if (value) {
      setFilters({ [selectedOption]: value });
    } else {
      setFilters({});
    }
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <div className="dashboard">
        <h1 className="ad-dash-title">Applications</h1>

        <div className="searches">
          <select
            className="selector"
            id="dropdown"
            value={selectedOption}
            onChange={handleDropdownChange}
          >
            <option value="first">First Name</option>
            <option value="last">Last Name</option>
            <option value="email">Email Address</option>
            <option value="school">University</option>
            <option value="status">Application Status</option>
          </select>
          <input
            className="find-selector"
            name="find-selector"
            type="text"
            placeholder={
              selectedOption.charAt(0).toUpperCase() +
              selectedOption.slice(1).toLowerCase()
            }
            onChange={handleFilterChange}
          ></input>
          <button
            className="apply-filter"
            onClick={() => fetchApplications(1, perPage, filters)}
          >
            Apply Filter
          </button>
        </div>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="get-users">
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Email Address</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>University</th>
                    <th>Application Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {applications.map((user, index) => (
                    <tr
                      key={user.email}
                      className={index % 2 === 0 ? "even-row" : "odd-row"}
                    >
                      <td>{user.email}</td>
                      <td>{user.first}</td>
                      <td>{user.last}</td>
                      <td>{user.school}</td>
                      <td>{user.status}</td>
                      <td>
                        <a
                          className="users-table-view"
                          href={`/admin/users/${user.email}`}
                        >
                          View User
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="ad-users-bottom-div">
              <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page <= 1}
                className="t-button"
              >
                Previous
              </button>
              <span className="pages">
                {" "}
                Page {page} of {pages} (Total: {total}){" "}
              </span>
              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page >= pages}
                className="t-button"
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
