import "./error.css";
import Logo from "../../media/logo.png";

const Error = () => {
  return (
    <div className="error-container">
      <h1>Oops! You've reached a 404 Error.</h1>
      <img
        className="logo"
        src={Logo}
        alt="HackPrinceton semester-specific theme graphic"
      />
    </div>
  );
};

export default Error;
