import "./dashboard.css";

const ProgressComponent = ({ currentState, complete }) => {
  return (
    <div class="dashboard-progress-item">
      {complete && <div class="dashboard-progress-item-icon">&#10003;</div>}
      {!complete && <div class="dashboard-progress-item-icon-empty"></div>}
      <span class="dashboard-progress-item-text">{currentState}</span>
    </div>
  );
};

export default ProgressComponent;
