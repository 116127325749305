import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from '../../hooks/useAuth';

const RequireNoAuth = () => {
  const { authState } = useAuth();
  const location = useLocation();

    if (authState.isAuthenticated)
      return <Navigate to="/dashboard" state={{ from: location }} replace />;
    return <Outlet />
}

export default RequireNoAuth