import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./loading.css";

const Loading = () => {
  return (
    <div className="loading-container-component">
    <ClipLoader
        className="wrapper"
        color="var(--secondary-color)"
        loading={true}
        size={150}
    />
    </div>
  );
};

export default Loading;
