import "./adminDashboard.css";
import AdminCard from "./adminCard";

const AdminDashboard = () => {
  return (
    <div className="dashboard">
      <h1 className="ad-dash-title">Admin Panel</h1>

      <div className="cards">
        <div className="level-cards">
          <AdminCard
            link="/admin/users"
            title="Applications"
            description="Information about users who submitted an application."
          />
          <AdminCard
            link="/admin/stats"
            title="Statistics"
            description="View statistics relating to the applicant and attendee pools."
          />
          <AdminCard
            link="/admin/batch"
            title="Batch Decisions"
            description="Batch accept, waitlist or reject hackers."
          />
        </div>
        <div className="level-cards">
          <AdminCard
            link="/admin/administrators"
            title="Admin Users"
            description="Add and manage admin users."
          />
          <AdminCard
            link="/admin/config"
            title="Configuration"
            description="Manage the site configuration."
          />
          <AdminCard
            link="/admin/resumebook"
            title="Resume Book"
            description="Create a Resume Book of all our Hackers!"
          />
        </div>
        {/* Next semester features */}
        {/* <div className="level-cards"> */}
        {/* <AdminCard
            link="/admin/sponsors"
            title="Sponsors"
            description="View HackPrinceton's sponsors and their committments."
          /> */}
        {/* <AdminCard
            link="/admin/applicationdesigner"
            title="Application Designer"
            description="Determine the questions for this semester's hackathon applications!"
          /> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default AdminDashboard;
