import React, { useState } from "react";
import axiosInstance from "../../../api/axios";
import "./resumebook.css";

const Resumebook = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [messageAll, setMessageAll] = useState("");

  const handleDownload = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.get(
        `/api/resume/download/${email}`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${email}_resume.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setMessage("Resume downloaded successfully");
    } catch (error) {
      setErrorMessage("Error Occurred: User Does Not Exist");
    }
  };

  const handleDownloadAll = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.get("/api/resume/download-all", {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "all_resumes.zip");
      document.body.appendChild(link);
      link.click();
      link.remove();
      setMessageAll("All resumes downloaded successfully");
    } catch (error) {
      setMessageAll(error.response.data.message);
    }
  };

  return (
    <div>
      <div class="dashboard">
        <h1 className="ad-dash-title">Resume Book</h1>

        <div className="searches-ad">
          <form onSubmit={handleDownload}>
            <h1 className="title-ad">Download a User's Resume</h1>
            <p>Enter the email of a single user to dowload their resume</p>
            {errorMessage && (
              <p style={{ color: "#c70314", fontSize: 20 }}>{errorMessage}</p>
            )}
            {message && (
              <p style={{ color: "var(--tertiary-color)", fontSize: 20 }}>
                {message}
              </p>
            )}
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter user's email"
              className="find-selector-ad"
              required
            />
            <button className="button-resume" type="submit">
              Download Resume
            </button>
          </form>
        </div>

        <div className="get-users-ad">
          <h1 className="title-ad">Download All Resumes</h1>
          <p>Download a zip file of the resumes of all CONFIRMED attendees</p>
          <button className="button-resume" onClick={handleDownloadAll}>
            Download All
          </button>
          {messageAll && (
            <p style={{ color: "var(--tertiary-color)", fontSize: 20 }}>
              {messageAll}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Resumebook;
