import "../create-admins/createAdmins.css";
import "./batch.css";
import { useState } from "react";
import axiosInstance from "../../../api/axios";

const Batch = () => {
  const [acceptanceList, setAcceptanceList] = useState("");
  const [emails, setEmails] = useState([]);
  const [approvedEmails, setApprovedEmails] = useState([]);
  const [deniedEmails, setDeniedEmails] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [batchType, setBatchType] = useState("accepted");
  const [travelReimbursement, setTravelReimbursement] = useState("false");
  const [housing, setHousing] = useState("false");

  const handleAcceptances = (event) => {
    const input = event.target.value;
    setAcceptanceList(input);

    const EMAIL_REGEX = /[\w.-]+@[\w.-]+\.[A-Za-z]{2,}/g;
    const emailArray = input.match(EMAIL_REGEX) || [];
    setEmails(emailArray);
  };

  const handleBatch = (event) => {
    setBatchType(event.target.value);
  };

  const handleSubmitAcceptances = async (event) => {
    event.preventDefault();
    setApprovedEmails([]);
    setDeniedEmails([]);

    for (const email of emails) {
      try {
        const response = await axiosInstance.post(
          `/api/applications/status/${email}`,
          {
            status: batchType,
            travel_reimbursement: travelReimbursement === "true",
            housing_accommodations: housing === "true",
          }
        );

        setApprovedEmails((prevEmails) => [...prevEmails, email]);
      } catch (err) {
        console.error(err);
        setDeniedEmails((prevEmails) => [...prevEmails, email]);
      }
    }

    setFormSubmitted(true);
    setAcceptanceList("");
    setEmails([]);
  };

  return (
    <div>
      <div className="dashboard">
        <h1 className="header-title-batch">Batch Update</h1>
        <div className="searches-ad">
          <form onSubmit={handleSubmitAcceptances}>
            <h1 className="title-ad">
              Batch {batchType.charAt(0).toUpperCase() + batchType.slice(1)}{" "}
              Hackers
            </h1>

            <select onChange={handleBatch} className="batch-select">
              <option value="accepted">Accept</option>
              <option value="waitlisted">Waitlist</option>
              <option value="rejected">Reject</option>
              <option value="waitlist_accepted">Accept off Waitlist</option>
            </select>

            <p>
              Submit a list of emails and batch {batchType} all hackers in the
              list
            </p>
            <p>
              Note: Hackers will only be notified of their status update when
              the hackathon status is changed to released
            </p>
            <textarea
              className="acceptances-input"
              name="email"
              placeholder="List emails here..."
              value={acceptanceList}
              onChange={handleAcceptances}
            />
            <br />

            {/* Travel Reimbursement */}
            <div className="batch-trav-re">
              <label>Travel Reimbursement:</label>
              <select
                value={travelReimbursement}
                onChange={(e) => setTravelReimbursement(e.target.value)}
                className="batch-select-options"
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
            </div>

            {/* Housing Accommodations */}
            <div>
              <label>Housing Accommodations:</label>
              <select
                value={housing}
                onChange={(e) => setHousing(e.target.value)}
                className="batch-select-options"
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
            </div>

            <br />
            <button className="button-batch" type="submit">
              Submit {batchType.charAt(0).toUpperCase() + batchType.slice(1)}
            </button>
          </form>
        </div>

        {formSubmitted && (
          <div className="accepted-or-failed-div">
            <div className="accepted-div">
              <h1 className="acceptances-emails">
                {batchType.charAt(0).toUpperCase() + batchType.slice(1)}{" "}
                Approved
              </h1>
              <ul>
                {approvedEmails.map((email, index) => (
                  <li className="accept" key={index}>
                    {email}
                  </li>
                ))}
              </ul>
            </div>
            <div className="accepted-div">
              <h1 className="acceptances-emails">
                {batchType.charAt(0).toUpperCase() + batchType.slice(1)} Failed
              </h1>
              <ul>
                {deniedEmails.map((email, index) => (
                  <li className="accept" key={index}>
                    {email}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Batch;
