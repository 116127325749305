import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./loading.css";

const LoadingPage = () => {
  return (
    <div className="body">
      <div className="loading-container">
        <ClipLoader
          className="wrapper"
          color="var(--secondary-color)"
          loading={true}
          size={150}
        />
      </div>
    </div>
  );
};

export default LoadingPage;
