import React, { useState, useEffect } from "react";
import "./stats.css";
import axiosInstance from "../../../api/axios";

const Stats = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axiosInstance.get("/api/applications/stats");
        setStats(response.data);
      } catch (error) {
        setError("Error fetching statistics");
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  const handleDownload = async (filter) => {
    try {
      setError("");
      const response = await axiosInstance.get("/api/applications/export", {
        params: { filters: JSON.stringify(filter) },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "applications.csv");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      setError("Error downloading CSV");
    }
  };

  const cards = [
    {
      title: "Total Applications",
      count: loading ? "loading..." : stats.total_applications,
      filter: {},
    },
    {
      title: "Princeton Applications",
      count: loading ? "loading..." : stats.princeton_applications,
      filter: { school: "Princeton University" },
    },
    {
      title: "Non-Princeton Applications",
      count: loading ? "loading..." : stats.non_princeton_applications,
      filter: { school: { $ne: "Princeton University" } },
    },
    {
      title: "Non-Princeton Confirmed",
      count: loading ? "loading..." : stats.non_princeton_confirmed,
      filter: { school: { $ne: "Princeton University" }, status: "confirmed" },
    },
    {
      title: "Non-Princeton Declined",
      count: loading ? "loading..." : stats.non_princeton_declined,
      filter: { school: { $ne: "Princeton University" }, status: "declined" },
    },
    {
      title: "Non-Princeton Accepted",
      count: loading ? "loading..." : stats.non_princeton_accepted,
      filter: { school: { $ne: "Princeton University" }, status: { $in: ["accepted", "waitlist_accepted"] } },
    },
  ];

  return (
    <div className="dashboard">
      <h1 className="ad-dash-title">HackPrinceton Statistics</h1>
      <div className="stats-cards-div">
        {error && <span style={{ color: "red" }}>{error}</span>}
        <div className="stats-cards">
          {cards.map((card, index) => (
            <div
              key={index}
              className="stats-card"
              onClick={() => handleDownload(card.filter)}
            >
              <h3>{card.title}</h3>
              <p>{card.count}</p>
              <div className="tooltip">Click to export as .csv</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stats;
