import { useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import axiosInstance from "../../../api/axios";
import "./adminTable.css";

const AdminTable = ({ administrators, fetchAdmins }) => {
  const { authState } = useAuth();

  useEffect(() => {
    if (authState.isAuthenticated) {
      fetchAdmins();
    }
  }, [authState.isAuthenticated]);

  const removeAdmin = async (email) => {
    try {
      const response = await axiosInstance.post("/api/auth/demote", { email });
      // console.log("Admin removed:", response.data);
      fetchAdmins();
    } catch (err) {
      console.error("Error removing admin:", err);
    }
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Email Address</th>
            <th style={{ width: "150px" }}></th>
          </tr>
        </thead>
        <tbody>
          {administrators.map((row, index) => (
            <tr
              key={row.id}
              className={index % 2 === 0 ? "even-row" : "odd-row"}
            >
              <td>{row}</td>
              <td>
                <button
                  className="delete-admin-button"
                  onClick={() => removeAdmin(row)}
                >
                  Remove Admin
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminTable;
