import Mail from "../../media/mail.png";
import Instagram from "../../media/insta.png";
import Facebook from "../../media/facebook.png";
import X from "../../media/twitter.png";
import CodeOfConduct from "../../media/mlh-code-of-conduct.pdf";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div>
        <ul className="horizontal-logos">
          <li>
            <a href="mailto:team@hackprinceton.com">
              <img className="mini-image" src={Mail} alt="Mail"></img>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/hackprinceton/">
              <img className="mini-image" src={Instagram} alt="Instagram"></img>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/hackprinceton">
              <img className="mini-image" src={Facebook} alt="Facebook"></img>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/hackprinceton">
              <img className="mini-image" src={X} alt="X"></img>
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-text">
        <a href="https://www.princetoneclub.com/">
          2024 Princeton Entrepreneurship Club
        </a>
        <div className="footer-text-bottom">
          <a
            href={CodeOfConduct}
            download="Code_of_Conduct.pdf"
          >
            Code of Conduct
          </a>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSe7yFEkcONrpiuHsq0mgKeMDj9Sr0A4EPhqM3SrD3EYGOfJxA/viewform?usp=sf_link">
            Report Bugs
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
