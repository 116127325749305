import "./introAnimation.css";

const IntroAnimation = () => {
  return (
    <div>
      <div className="an-b">
        <div>
          <div className="wrapper">
            <div className="base">
              <div className="base-bottom"></div>
              <div className="lock-inside-top"></div>
              <div className="lock-inside-bottom"></div>
            </div>
            <div className="lock-circle">
              <div className="lock-circle-right"></div>
            </div>
          </div>
          <div className="animation-title-div">
            <h1 className="animation-title">HackHeist</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroAnimation;
