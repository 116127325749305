import { useEffect, useState } from "react";
import Logo from "../../media/logo.png";
import "../register/register.css";
import axiosInstance from "../../api/axios.js";
import { useParams } from "react-router-dom";

const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[-~!@#$%^&*()_?]).{8,}$/;

const ResetRequest = () => {
  const { token } = useParams();

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);

  const [message, setMessage] = useState("");

  useEffect(() => {
    setValidPassword(PASSWORD_REGEX.test(password));
    setValidConfirmPassword(password === confirmPassword);
  }, [password, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post(
        `/api/auth/reset_password/${token}`,
        { password }
      );
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <div className="register">
      <div className="row-columns">
        <div className="auth">
          <div className="page-meta">
            <h1 className="title">Reset Password</h1>
          </div>

          <div className="complete-log-form">
            <form className="auth-form" onSubmit={handleSubmit}>
              <div class="auth-form-inner">
                {message && <span style={{ color: "red" }}>{message}</span>}
                <input
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                ></input>
                {password && !validPassword && (
                  <span style={{ color: "red" }}>
                    Password must have at least 8 characters, 1 number, and 1
                    special character
                  </span>
                )}
                <input
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                ></input>
                {password && confirmPassword && !validConfirmPassword && (
                  <span style={{ color: "red" }}>
                    This field must match the password
                  </span>
                )}
                <div class="auth-form__lower">
                  <button
                    type="submit"
                    class="button"
                    placeid="auth-button"
                    disabled={!validPassword || !validConfirmPassword}
                  >
                    <a className="reg-button">Reset</a>
                  </button>
                </div>
              </div>
            </form>
          </div>

          <img
            className="logo"
            src={Logo}
            alt="HackPrinceton semester-specific theme graphic"
          />
        </div>
      </div>
    </div>
  );
};

export default ResetRequest;
