import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";

import Layout from "./Layout.js";
import RequireNoAuth from "./components/routes/RequireNoAuth.js";
import RequireAuth from "./components/routes/RequireAuth.js";
import "./root.css";
import Nav from "./components/nav/nav.js";

// Auth Pages
import Register from "./pages/register/register.js";
import Login from "./pages/login/login.js";
import ResetRequest from "./pages/reset/resetRequest.js";
import ResetPassword from "./pages/reset/resetPassword.js";

// User Pages
import Dashboard from "./pages/dashboard/dashboard.js";
import Apply from "./pages/apply/apply.js";

// Admin Pages
import AdminDashboard from "./pages/admin/adminDashboard.js";
// import Sponsors from "./pages/admin/sponsors/sponsors.js";
import Resumebook from "./pages/admin/resumebook/resumebook.js";
import Users from "./pages/admin/users/users.js";
import ViewUser from "./pages/admin/users/view-user/viewUser.js";
import Stats from "./pages/admin/stats/stats.js";
import CreateAdmins from "./pages/admin/create-admins/createAdmins.js";
import Config from "./pages/admin/config/config.js";
import AnimationWrapper from "./pages/intro-animation/animationWrapper.js";

// Other
import Footer from "./components/footer/footer.js";
import LoadingPage from "./components/loading/loadingPage.js";
import Error from "./pages/error/error.js";
import Verify from "./pages/register/verify.js";
import Batch from "./pages/admin/batch/batch.js";
import MatrixAnimation from "./components/background/matrix.js";
import Demographics from "./pages/admin/demographics/demographics.js";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Nav />
        <MatrixAnimation />
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* public routes */}
            <Route element={<RequireNoAuth />}>
              <Route path="/" element={<AnimationWrapper />} />
              <Route path="login" element={<Login />} />
              <Route path="*" element={<Error />} />
              <Route path="register" element={<Register />} />
              <Route path="verify/:token" element={<Verify />} />
              <Route path="reset" element={<ResetRequest />} />
              <Route path="reset/:token" element={<ResetPassword />} />
              <Route path="/loading" element={<LoadingPage />} />
              {/* <Route path="/matrix" element={<MatrixAnimation />} /> */}
            </Route>

            {/* protected routes */}
            <Route element={<RequireAuth needAdmin={false} />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="apply" element={<Apply />} />
            </Route>

            {/* admin routes */}
            <Route element={<RequireAuth needAdmin={true} />}>
              <Route path="admin/" element={<AdminDashboard />} />
              <Route path="admin/users" element={<Users />} />
              <Route path="admin/stats" element={<Stats />} />
              <Route path="admin/administrators" element={<CreateAdmins />} />
              <Route path="admin/config" element={<Config />} />
              <Route path="admin/users/:email" element={<ViewUser />} />
              {/* <Route path="admin/sponsors" element={<Sponsors />} /> */}
              <Route path="admin/resumebook" element={<Resumebook />} />
              <Route path="admin/batch" element={<Batch />} />
              <Route path="/admin/demographics" element={<Demographics />} />
            </Route>
          </Route>
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
