import "./register.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";

const EMAIL_REGEX =
  /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.(edu|ca|in|ng|uk|it)|ontariotechu.net)$/;
const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[-~!@#$%^&*()_?]).{8,}$/;

// Contact me page
const RegistrationForm = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPassword(PASSWORD_REGEX.test(password));
    setValidConfirmPassword(password === confirmPassword);
  }, [password, confirmPassword]);

  useEffect(() => {
    setErrorMessage("");
  }, [email, password, confirmPassword]);

  const submitForm = async (e) => {
    e.preventDefault();

    // If button enabled with JS hack
    if (!EMAIL_REGEX.test(email) || !PASSWORD_REGEX.test(password)) {
      setErrorMessage("Invalid Entry");
      return;
    }

    try {
      await axiosInstance.post("/api/auth/register", { email, password });
      setSuccessMessage(
        "Verification Email Sent. Check Spam folder if email is not visible."
      );
    } catch (err) {
      if (err.response.data.message === "User with this email already exists")
        setErrorMessage("User with this email already exists");
      else setErrorMessage("Registration Failed");
    }
  };

  return (
    <div className="complete-log-form">
      <form className="auth-form">
        <p className="subtitle">
          Create an account with a student email to submit and track your
          application.
        </p>
        <div className="auth-form-inner">
          {successMessage && <p className="error-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {email && !validEmail && (
            <p className="error-message">
              Must be a student email assigned by a university
            </p>
          )}
          <input
            type="text"
            placeholder="Student Email Address"
            id="email"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          ></input>
          {password && !validPassword && (
            <p className="error-message">
              Password must have at least 8 characters, 1 number, and 1 special
              character
            </p>
          )}
          <input
            type="password"
            className="auth-form-password"
            placeholder="Password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          ></input>

          {password && confirmPassword && !validConfirmPassword && (
            <p className="error-message">This field must match the password</p>
          )}

          <input
            type="password"
            placeholder="Confirm Password"
            className="auth-form-password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          ></input>

          <p className="auth-meta">
            <a className="auth-meta" href="/login">
              Already have an account?
            </a>
          </p>

          <div className="auth-form__lower">
            {!successMessage && (
              <button
                type="submit"
                className="button"
                placeid="auth-button"
                disabled={
                  !validEmail || !validPassword || !validConfirmPassword
                }
                onClick={submitForm}
              >
                Register
              </button>
            )}
            {successMessage && (
              <button type="submit" className="button" placeid="auth-button">
                <a href="/login" className="return">
                  Return to Login
                </a>
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
