import "./dashboard.css";
import ProgressComponent from "./ProgressComponent.js";

const DashboardProgress = ({ status }) => {
  if (status === "unsubmitted" || status === "started") {
    return (
      <div className="small-12-columns">
        <h1 className="dash-title">Dashboard</h1>

        <div class="dashboard-progress">
          <ProgressComponent currentState="Apply" complete={false} />
          <ProgressComponent currentState="Review" complete={false} />
          <ProgressComponent currentState="Confirm" complete={false} />
          <ProgressComponent currentState="Attend" complete={false} />
        </div>
      </div>
    );
  }

  if (status === "submitted") {
    return (
      <div className="small-12-columns">
        <h1 className="dash-title">Dashboard</h1>

        <div class="dashboard-progress">
          <ProgressComponent currentState="Apply" complete={true} />
          <ProgressComponent currentState="Review" complete={false} />
          <ProgressComponent currentState="Confirm" complete={false} />
          <ProgressComponent currentState="Attend" complete={false} />
        </div>
      </div>
    );
  }

  if (status === "waitlisted" || status === "waitlist_confirmed") {
    return (
      <div className="small-12-columns">
        <h1 className="dash-title">Dashboard</h1>

        <div class="dashboard-progress">
          <ProgressComponent currentState="Apply" complete={true} />
          <ProgressComponent currentState="Review" complete={true} />
          <ProgressComponent currentState="Waitlist" complete={true} />
          <ProgressComponent currentState="Confirm" complete={false} />
          <ProgressComponent currentState="Attend" complete={false} />
        </div>
      </div>
    );
  }

  if (status === "accepted" || status === "waitlist_accepted") {
    return (
      <div className="small-12-columns">
        <h1 className="dash-title">Dashboard</h1>

        <div class="dashboard-progress">
          <ProgressComponent currentState="Apply" complete={true} />
          <ProgressComponent currentState="Review" complete={true} />
          <ProgressComponent currentState="Confirm" complete={false} />
          <ProgressComponent currentState="Attend" complete={false} />
        </div>
      </div>
    );
  }

  if (status === "confirmed") {
    return (
      <div className="small-12-columns">
        <h1 className="dash-title">Dashboard</h1>

        <div class="dashboard-progress">
          <ProgressComponent currentState="Apply" complete={true} />
          <ProgressComponent currentState="Review" complete={true} />
          <ProgressComponent currentState="Confirm" complete={true} />
          <ProgressComponent currentState="Attend" complete={false} />
        </div>
      </div>
    );
  }

  if (status === "rejected") {
    return (
      <div className="small-12-columns">
        <h1 className="dash-title">Dashboard</h1>

        <div class="dashboard-progress">
          <ProgressComponent currentState="Apply" complete={true} />
          <ProgressComponent currentState="Review" complete={true} />
        </div>
      </div>
    );
  }

  if (status === "declined") {
    return (
      <div className="small-12-columns">
        <h1 className="dash-title">Dashboard</h1>

        <div class="dashboard-progress">
          <ProgressComponent currentState="Apply" complete={true} />
          <ProgressComponent currentState="Review" complete={true} />
          <ProgressComponent currentState="Confirm" complete={false} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="small-12-columns">
        <h1 className="dash-title">Dashboard</h1>
      </div>
    );
  }
};

export default DashboardProgress;
