import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../media/logo.png";
import "./nav.css";
import axiosInstance from "../../api/axios";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";

const Nav = () => {
  const { authState, setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();
  const [hackathonStatus, setHackathonStatus] = useState("loading");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await axiosInstance.get(
          "/api/applications/cycle/status"
        );
        setHackathonStatus(response.data);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchStatus();
  }, []);

  const handleLogout = async () => {
    try {
      await axiosInstance.post(
        "/api/auth/logout",
        {},
        { withCredentials: true }
      );
      setAuthState({ isAuthenticated: false, user: null });
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="navigation">
      <div className="left-side">
        <a href="https://www.hackprinceton.com/">
          <img src={Logo} alt="Logo" className="nav-link-img" />
        </a>
        <NavLink to="https://www.hackprinceton.com/" className="nav-link">
          HackPrinceton
        </NavLink>
      </div>
      <div className="right-side">
        <button className="menu-toggle" onClick={toggleMenu}>
          ☰
        </button>
        <div className={`nav-links ${isMenuOpen ? "open" : ""}`}>
          {authState.isAuthenticated ? (
            <div className="nav-apply-button">
              {authState.isAuthenticated && authState.user.role === "admin" && (
                <NavLink to="/admin" className="nav-link">
                  Admin
                </NavLink>
              )}
              {hackathonStatus === "open" && (
                <NavLink to="/apply" className="nav-link">
                  Apply
                </NavLink>
              )}
              <NavLink to="/dashboard" className="nav-link">
                Dashboard
              </NavLink>
              <button onClick={handleLogout} className="nav-link-but">
                Logout
              </button>
            </div>
          ) : (
            <div className="nav-auth-section">
              <NavLink to="/register" className="nav-link">
                Register
              </NavLink>
              <NavLink to="/login" className="nav-link">
                Login
              </NavLink>
            </div>
          )}
        </div>
        <a
          id="mlh-trust-badge"
          href="https://mlh.io/na?utm_source=na-hackathon&utm_medium=TrustBadge&utm_campaign=2025-season&utm_content=white"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://s3.amazonaws.com/logged-assets/trust-badge/2025/mlh-trust-badge-2025-white.svg"
            alt="Major League Hacking 2025 Hackathon Season"
            className="mlh-badge"
          />
        </a>
      </div>
    </div>
  );
};

export default Nav;
