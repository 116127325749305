import { useContext, useEffect, useState } from 'react';
import AuthContext from '../context/AuthContext';
import axiosInstance from '../api/axios';

const useAuth = () => {
  const { authState, setAuthState } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axiosInstance.get('/api/auth/user');
        setAuthState({ isAuthenticated: true, user: response.data });
      } catch (error) {
        setAuthState({ isAuthenticated: false, user: null });
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, [setAuthState]);

  return { authState, loading };
};

export default useAuth;


// import { useContext } from 'react';
// import AuthContext from '../context/AuthContext';

// const useAuth = () => {
//   return useContext(AuthContext);
// };

// export default useAuth;