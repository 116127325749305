import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Loading from "../loading/loadingPage";

const RequireAuth = ({ needAdmin }) => {
  const { authState, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <Loading />; // Or any loading spinner/component you prefer
  }

  if (!authState.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (needAdmin && authState.user.role !== "admin") {
    return <Navigate to="/dashboard" replace />;
  }

  return <Outlet />;
};

export default RequireAuth;
