import { useEffect, useState } from "react";
import Logo from "../../media/logo.png";
import "./register.css";
import { useParams } from "react-router-dom";
import axiosInstance from "../../api/axios";
import LoadingPage from "../../components/loading/loadingPage";

const Verify = () => {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const verifyAccount = async () => {
      try {
        const response = await axiosInstance.get(`/api/auth/verify/${token}`);
        // console.log(response)
        setSuccess(true);
      } catch (error) {
        // console.log(error)
        setSuccess(false);
      }
      setLoading(false);
    };

    verifyAccount();
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingPage />
      ) : (
        <div className="register">
          <div className="row-columns">
            <div className="auth">
              {success ? (
                <div className="page-meta">
                  <h1 className="title">Account Created!</h1>
                  <form className="auth-form">
                    <p className="subtitle">
                      Your account was successfully created!
                    </p>
                    <p className="subtitle">Please login and apply!</p>
                  </form>
                </div>
              ) : (
                <div className="page-meta">
                  <h1 className="title">Invalid URL</h1>
                  <form className="auth-form">
                    <p className="subtitle">
                      Email verifications expire roughly 1 hour after they were
                      initially sent.
                    </p>
                    <p className="subtitle">
                      Please register once again and contact us at
                      team@hackprinceton.com if issues persist.
                    </p>
                  </form>
                </div>
              )}

              <img
                className="logo"
                src={Logo}
                alt="HackPrinceton semester-specific theme graphic"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Verify;
