import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import axiosInstance from "../../../api/axios";
import AdminTable from "./adminTable";
import "./createAdmins.css";

const CreateAdmins = () => {
  const { authState } = useAuth();
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [administrators, setAdministrators] = useState([]);

  const fetchAdmins = async () => {
    try {
      const response = await axiosInstance.get("/api/auth/admins");
      setAdministrators(response.data);
    } catch (err) {
      // console.log(err);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axiosInstance.post("/api/auth/promote", {
        email: email,
      });
      // console.log("Admin created:", response.data);
      setIsError(false);
      setEmail("");
      fetchAdmins();
    } catch (err) {
      setIsError(true);
      setError(err);
      console.error("Error creating admin:", err);
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div>
      <div class="dashboard">
        <h1 className="ad-dash-title">Administrators</h1>

        <div className="searches-ad">
          <form onSubmit={handleFormSubmit}>
            <h1 className="title-ad">Create Admin</h1>
            <p>Grant admin privileges to another user</p>
            {isError && (
              <p style={{ color: "var(--secondary-color)", fontSize: 20 }}>
                Invalid Email - User does not exist
              </p>
            )}
            <input
              className="find-selector-ad"
              name="email"
              type="text"
              placeholder="User Email"
              value={email}
              onChange={handleEmailChange}
            ></input>
            <button className="button-ad" type="submit">
              Make Admin
            </button>
          </form>
        </div>

        <div className="get-users-ad">
          <h1 className="title-ad">Current Admins</h1>
          <AdminTable
            administrators={administrators}
            fetchAdmins={fetchAdmins}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateAdmins;
