import RegistrationForm from "./registrationForm.js";
import Logo from "../../media/logo.png";
import "./register.css";

const Register = () => {
  return (
    <div className="register">
      <div className="row-columns">
        <div className="auth">
          <div className="page-meta">
            <h1 className="title">Get Started</h1>
          </div>

          <RegistrationForm />

          <img
            className="logo"
            src={Logo}
            alt="HackPrinceton semester-specific theme graphic"
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
