import React, { useState } from "react";
import "../apply.css";

const Autocomplete = ({
  options,
  answer,
  updateValue,
  sectionIndex = 0,
  questionIndex = 0,
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [inputValue, setInputValue] = useState(answer);
  const [showOptions, setShowOptions] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    updateValue(sectionIndex, questionIndex, value);

    if (value) {
      const filtered = options.filter((option) =>
        option.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOptions(filtered);
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };

  const pattern = `^(${options.join("|")})$`;

  const handleOptionClick = (option) => {
    setInputValue(option);
    updateValue(sectionIndex, questionIndex, option);
    setFilteredOptions([]);
    setShowOptions(false);
  };

  const handleClickOutside = (e) => {
    if (e.target.closest(".autocomplete-container") === null) {
      setShowOptions(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    setInputValue(answer);
  }, [answer]);

  return (
    <div className="autocomplete-container">
      <input
        type="text"
        className="autocomplete"
        value={inputValue}
        onChange={handleInputChange}
        pattern={pattern}
        required
      />
      {showOptions && (
        <div className="autocomplete-items">
          {filteredOptions.map((option, index) => (
            <div
              key={index}
              className="autocomplete-option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
