import "./adminDashboard.css";

const AdminCard = ({ link, title, description }) => {
  return (
    <div className="card">
      <a className="link" href={link}>
        <h1 className="card-title">{title}</h1>
        <p className="card-desc">{description}</p>
      </a>
    </div>
  );
};

export default AdminCard;
